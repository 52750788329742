<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Manage downloads</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Downloads
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn @click="downloadCategory()" x-large text> Categories </v-btn>
                  <v-btn
                    v-if="checkIsAccessible('download', 'create')"
                    @click="createDownload()"
                    class="mt-1 btn btn-primary"
                    style="color: #fff"
                  >
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp; Add
                    download
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div
              class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start"
            >
              <v-row>
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Title"
                    type="text"
                    v-on:keyup.enter="getDownloads()"
                    dense
                    outlined
                    v-model="search.title"
                  />
                </v-col>

                <v-col cols="12" md="3">
                  <v-select
                    label="Download Type"
                    outlined
                    dense
                    :items="types"
                    item-text="title"
                    v-on:keyup.enter="getDownloads()"
                    item-value="id"
                    v-model="search.type"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="3">
                  <v-select
                    label="Status"
                    outlined
                    dense
                    :items="active_statuses"
                    item-text="title"
                    item-value="value"
                    v-model="search.active"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="3" class="text-right">
                  <v-btn
                    @click="getDownloads()"
                    class="btn btn-primary btn-search"
                    :loading="loading"
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                  <!--                                <button class="btn btn-standard  ml-2" @click="_reset">Reset</button>-->
                </v-col>
              </v-row>
            </div>
            <div class="">
              <v-skeleton-loader type="table-thead" v-if="loading"> </v-skeleton-loader>

              <v-skeleton-loader v-if="loading" type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table">
                <thead>
                  <tr class="px-3">
                    <th class="px-3 wrap-column">Title</th>
                    <th class="px-3 wrap-column">Type</th>
                    <th class="pr-3 text-center">Action</th>
                  </tr>
                </thead>
                <draggable
                  @change="sort"
                  v-model="downloads"
                  v-if="downloads.length > 0"
                  class="text-left"
                  tag="tbody"
                >
                  <tr v-for="download of downloads" :key="download.id">
                    <td class="px-3 wrap-column">
                      <i class="cursor-drag fas fa-sort handle pr-2"></i>&nbsp;&nbsp;
                      <i
                        :class="download.is_private ? 'fa fa-lock' : 'fa fa-lock-open'"
                      ></i
                      >&nbsp;
                      <a href="#" @click="editDownload(download)" class="mr-2">
                        {{ download.title }}</a
                      >&nbsp;&nbsp;
                      <i
                        class="fas fa-circle"
                        :class="download.is_active ? 'dot-active' : 'dot-inactive'"
                      ></i>
                    </td>
                    <td class="px-3 wrap-column">
                      {{ download.type_name }}
                    </td>

                    <td class="pr-0 text-center">
                      <b-dropdown
                        size="sm"
                        variant="link"
                        toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                        no-caret
                        right
                        no-flip
                      >
                        <template v-slot:button-content>
                          <slot>
                            <span>
                              <i class="flaticon-more-1"></i> </span
                          ></slot>
                        </template>
                        <!--begin::Navigation-->
                        <div class="navi navi-hover">
                          <b-dropdown-text
                            tag="div"
                            class="navi-item"
                            v-if="checkIsAccessible('download', 'show')"
                          >
                            <a href="#" class="navi-link" @click="viewDownload(download)">
                              <span class="navi-icon">
                                <i class="fa fa-download"></i>
                              </span>
                              <span class="navi-text"> View </span>
                            </a>
                          </b-dropdown-text>

                          <b-dropdown-text
                            tag="div"
                            class="navi-item"
                            v-if="checkIsAccessible('download', 'edit')"
                          >
                            <a href="#" class="navi-link" @click="editDownload(download)">
                              <span class="navi-icon">
                                <i class="flaticon-edit"></i>
                              </span>
                              <span class="navi-text"> Edit </span>
                            </a>
                          </b-dropdown-text>

                          <b-dropdown-text
                            tag="div"
                            class="navi-item"
                            v-if="checkIsAccessible('download', 'delete')"
                          >
                            <a
                              href="#"
                              class="navi-link"
                              @click="deleteDownload(download.id)"
                            >
                              <span class="navi-icon">
                                <i class="fas fa-trash"></i>
                              </span>
                              <span class="navi-text"> Delete</span>
                            </a>
                          </b-dropdown-text>
                        </div>
                        <!--end::Navigation-->
                      </b-dropdown>
                    </td>
                  </tr>
                </draggable>
                <tr v-show="downloads.length == 0">
                  <td class="text-center" colspan="5">
                   <strong>
                     Please select a download type to fetch data.
                   </strong>
                  </td>
                </tr>
              </table>
              <b-pagination
                @input="getDownloads"
                v-if="downloads.length > 0"
                v-model="page"
                class="pull-right mt-7"
                :total-rows="total"
                :per-page="perPage"
                first-number
                last-number
                :disabled="loading"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
      <create-and-update ref="download" @refresh_list="getDownloads"></create-and-update>
    </div>
  </v-app>
</template>
<script>
import draggable from "vuedraggable";
import DownloadService from "@/core/services/download/DownloadService";
import DownloadTypeService from "@/core/services/download/type/DownloadTypeService";
import CreateAndUpdate from "./CreateAndUpdate";
import ProgramService from "@/core/services/level/program/ProgramService";

const downloadService = new DownloadService();
const typeService = new DownloadTypeService();
const programService = new ProgramService();

export default {
  display: "Table",
  order: 8,
  components: {
    CreateAndUpdate,
    draggable,
  },
  data() {
    return {
      active_statuses: [
        { title: "Active", value: "active" },
        { title: "Inactive", value: "in_active" },
      ],
      downloads: [],
      programs: [],
      types: [],
      page: null,
      total: null,
      perPage: null,
      search: {
        type: null,
        program_id: null,
        active: null,
      },
      loading: false,
    };
  },
  mounted() {
    this.getDownloads();
    this.getTypes();
    this.getPrograms();
  },
  methods: {
    getPrograms() {
      programService.all().then((response) => {
        this.programs = response.data;
      });
    },
    getTypes() {
      typeService.allActive().then((response) => {
        this.types = response.data;
      });
    },
    sort() {
      downloadService
        .sort(this.downloads)
        .then((res) => {
          this.$snotify.success("Sorted");
          this.getDownloads();
        })
        .catch((err) => {
          this.$snotify.success("Sorted Error");
        });
    },
    getDownloads() {
      this.loading = true;
      if (this.search.type != null) {
        downloadService
          .paginate(this.search)
          .then((response) => {
            this.downloads = response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.loading = false;
          })
          .catch((err) => {
            // console.log(err)
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.loading = false;
      }
    },
    createDownload() {
      this.$refs["download"].showModal();
    },
    downloadCategory() {
      this.$router.push({
        name: "download-type",
      });
    },
    editDownload(download) {
      this.$refs["download"].showModal(download.id);
    },
    viewDownload(download) {
      var domain = (function () {
        var i = 0,
          domain = document.domain,
          p = domain.split("."),
          s = "_gd" + new Date().getTime();
        while (i < p.length - 1 && document.cookie.indexOf(s + "=" + s) == -1) {
          domain = p.slice(-1 - ++i).join(".");
        }
        return domain;
      })();
      var webdomain = domain.replace(/^[^.]+\./g, "");
      webdomain = process.env.VUE_APP_PROTOCOL + webdomain + "/";
      // console.log(download.is_private);
      if (download.is_private == 1) {
        window.open(webdomain + "download/" + download.id + "/file", "_blank");
      } else {
        window.open(webdomain + "download/" + download.path, "_blank");
      }
    },
    _reset() {
      this.search.type = null;
      this.search.program_id = null;
      // this.getDownloads();
    },
    deleteDownload(id) {
      this.$confirm({
        message: `Are you sure you want to delete this item?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            downloadService.delete(id).then((response) => {
              this.$snotify.success("Information deleted");
              this.getDownloads();
            });
          }
        },
      });
    },
  },
};
</script>
