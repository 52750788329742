<template>
    <v-app>
            <v-dialog max-width="900" v-model="downloadDialog">
                <v-card>
                    <v-toolbar dark>
                        <v-card-title>
                          {{ `${download.id ? 'Update' : 'Add'} ` }} download
                        </v-card-title>

                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="hideModal">
                            <i class="fas fa-times"></i>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                        <v-card-text>
                            <div class="row">
                                <div class="col-12 form-group">
                                    <v-text-field outlined dense label="Title" v-model="download.title"></v-text-field>
                                    <span class="text-danger" v-if="$v.download.title.$error">This information is required</span>
                                </div>
                                <div class="col-12 form-group">
                                    <v-textarea outlined dense label="Description" v-model="download.description"></v-textarea>
                                </div>
                                <div class="col-12 form-group">

                                    <v-select
                                            :items="types"
                                            item-text="title"
                                            item-value="id"
                                            outlined
                                            dense
                                            label="Type"
                                            v-model="download.download_type_id"></v-select>

                                    <!-- <input type="text" class="form-control border border-dark" v-model="download.type" placeholder="Title" /> -->
                                    <span class="text-danger" v-if="$v.download.download_type_id.$error">This information is required</span>
                                </div>
                                <div class="col-12 form-group">
                                    <v-file-input
                                            outlined dense label="File"
                                            @change="checkFileSize($event)"
                                            accept="application/msword ,application/pdf, image/*, application/vnd/*"
                                            placeholder="File"
                                            prepend-icon=""
                                            prepend-inner-icon="mdi-link"
                                            v-model="download.path"></v-file-input>
                                    <span class="text-danger" v-if="$v.download.path.$error">This information is required</span>
                                    <span class="text-danger" v-if="sizeExceed">Please upload a file upt 20MB</span>
                                </div>
                                <div class="col-12 form-group">
                                    <v-file-input
                                            outlined dense label="File"
                                            @change="checkImageSize($event)"
                                            placeholder="File"
                                            prepend-icon=""
                                            prepend-inner-icon="mdi-image"
                                            v-model="download.preview_image"></v-file-input>
                                    <img v-if="download.preview_image_path" :src="download.preview_image_path.real" alt="">
                                    <span class="text-danger" v-if="sizeExceed">Please upload a file upt 20MB</span>
                                </div>
                                <div class="col-12 form-group">
                                    <v-text-field outlined dense label="Position" v-model="download.position"></v-text-field>

                                </div>

                                <div class="col-3 form-group">
                                    <v-switch v-model="download.is_private" label="Private File">Private file
                                    </v-switch>
                                </div>
                                <div class="col-3 form-group">
                                    <v-switch v-model="download.public_hidden"  label="Hide From Public">Hide from public
                                    </v-switch>
                                </div>

                                <div class="col-3 form-group">
                                    <v-switch v-model="download.is_active"  label="Status">Status
                                    </v-switch>
                                </div>

                                <div class="col-12 text-right">
                                    <v-btn
                                            class="btn text-gray btn-standard"
                                            depressed
                                            @click="hideModal"
                                    >Cancel
                                    </v-btn>
                                    <v-btn v-if="checkIsAccessible('download', 'create')"
                                            class="text-white ml-2 btn btn-primary"
                                            depressed
                                            @click="createOrUpdate"
                                            :loading="isBusy"
                                    >Save
                                    </v-btn>

                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
            </v-dialog>

    </v-app>
</template>
<script>
    import {required} from "vuelidate/lib/validators";

    import DownloadTypeService from "@/core/services/download/type/DownloadTypeService";
    import DownloadService from "@/core/services/download/DownloadService";

    const typeService = new DownloadTypeService();
    const downloaService = new DownloadService();

    export default {
        props: ["program_id"],
        validations: {
            download: {
                title: {required},
                path: {required},
                download_type_id: {required}
            }
        },
        data() {
            return {
                types: [],
                sizeExceed: false,
                edit: false,
                isBusy: false,
                downloadDialog: false,
                download: {
                    title: null,
                    description: null,
                    position: null,
                    path: null,
                    download_type_id: null,
                    is_private: null,
                    public_hidden: null,
                }
            };
        },
        mounted() {
            this.getTypes()
        },
        methods: {
            checkImageSize(file) {
              if(file){
                let size = file.size / 1024 / 1024;
                let mbSize = size.toFixed(2);
                if (mbSize > 20) {
                  this.sizeExceed = true;
                } else {
                  this.sizeExceed = false;
                }
              }
            },checkFileSize(file) {
              if(file){
                let size = file.size / 1024 / 1024;
                let mbSize = size.toFixed(2);
                if (mbSize > 20) {
                  this.sizeExceed = true;
                } else {
                  this.sizeExceed = false;
                }
              }
            },
            showModal(downloadId = null) {
                this.reset();
                if (downloadId != null) {
                    this.edit = true;
                    this.getDownload(downloadId);
                }
                this.downloadDialog = true;
            },
            hideModal() {
                this.downloadDialog = false;

            },
            toggleModal() {
                // We pass the ID of the button that we want to return focus to
                // when the modal has hidden
                this.$refs["my-modal"].toggle("#toggle-btn");
            },
            getTypes() {
                typeService.allActive().then(response => {
                    this.types = response.data;
                });
            }, getDownload(id) {
                downloaService.show(id).then(response => {
                    this.edit = true
                    this.download = response.data.download;
                    this.download.download_type_id = parseInt(response.data.download.download_type_id);

                });
            },
            generateFd() {
                let fd = new FormData();
                for (let key in this.download) {
                    if (
                        key == "path" &&
                        this.download["path"] &&
                        this.download["path"] != null
                    ) {
                        if (typeof (this.download['path']) == 'object') {
                            fd.append("path", this.download[key]);

                        }

                    } else if (
                        key == "preview_image" &&
                        this.download["preview_image"] &&
                        this.download["preview_image"] != null
                    ) {
                        if (typeof (this.download['preview_image']) == 'object') {
                            fd.append("preview_image", this.download[key]);

                        }

                    } else if (key == "is_active") {
                        fd.append("is_active", this.download["is_active"] ? 1 : 0);
                    } else if (key == "is_private") {
                        fd.append("is_private", this.download["is_private"] ? 1 : 0);
                    } else if (key == "public_hidden") {
                        fd.append("public_hidden", this.download["public_hidden"] ? 1 : 0);
                    } else {
                        if (this.download[key] != null) {
                            fd.append(key, this.download[key]);
                        }
                    }
                }
                return fd;
            },
            createOrUpdate() {
                this.$v.download.$touch();
                if (this.$v.download.$error) {
                    setTimeout(() => {
                        this.$v.download.$reset();
                    }, 3000);
                } else {
                    let fd = this.generateFd();
                    if (this.edit) {
                        this.updateDownload(fd);
                    } else {
                        this.createDownload(fd);
                    }
                }
            },
            createDownload(fd) {
                this.isBusy = true;
                downloaService.store(fd).then(response => {
                    this.isBusy = false;
                    this.$snotify.success("Information added");
                    this.resetForm()
                }).catch((err) => {
                    this.$snotify.error("Something Went Wrong");
                    this.isBusy = false;
                });
            },
            updateDownload(fd) {
                this.isBusy = true;
                downloaService.update(this.download.id, fd).then(response => {
                    this.isBusy = false;
                    this.resetForm()
                }).catch((err) => {
                    this.$snotify.error("Something Went Wrong");
                    this.isBusy = false;
                });
                ;
            },
            reset() {
                this.download = {
                    title: null,
                    path: null,
                    download_type_id: null
                };
            },
            resetForm() {
                this.edit = false;
                this.download = {
                    title: null,
                    path: null,
                    download_type_id: null
                };
                this.edit = false
                this.hideModal();
                this.$emit("refresh_list");
            }
        }
    };
</script>
